<template>
    <div class="second">
        <div class="second-title">
            {{second.title}}
        </div>
        <div class="content">
            <div class="cover-img"></div>
            <div class="texts">
                <div class="up-prev" @click="upPrev()"></div>
                <div class="texts-wrapper">
                    <div
                        v-for="(item, index) in second.classify"
                        :key="index"
                        class="swiper-slide"
                    >
                        <div
                            class="item"
                            :class="{active: index === activeIndex}"
                            @mouseenter="changeItem(index)"
                        >
                            <div class="line"></div>
                            <div class="title">
                                <span>0{{index+1}}</span><span>{{item.title}}</span>
                            </div>
                            <div class="intro">{{item.content}}</div>
                        </div>
                    </div>
                </div>
                <div class="down-next" @click="downNext()"></div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'SecondTeacher',
    props: {
        second: {
            type: Object,
            default: () => ({
                title: '贴心第二主讲 专业答疑',
                classify: [
                    {
                        title: '课前',
                        content: '提醒到课，课前复习，引导预习'
                    },
                    {
                        title: '课中',
                        content: '跟课伴读，习惯提醒，帮助归纳'
                    },
                    {
                        title: '课后',
                        content: '督促复习，课后答疑，给出建议'
                    },
                ]
            })
        }
    },
    data() {
        return {
            activeIndex: 0,
        };
    },
    methods: {
        changeItem(index) {
            this.activeIndex = index;
        },
        secondTeacherAnimation(dom) {
            const second = document.querySelector('.second');
            const cover = second.querySelector('.cover-img');
            const texts = second.querySelector('.texts');
            const up = texts.querySelector('.up-prev');
            const down = texts.querySelector('.down-next');
            const options = {
                threshold: 0.25
            };
            const domIo = new IntersectionObserver(entries => {
                const visible = entries[0].isIntersecting;
                if (visible) {
                    cover.classList.add('animate-active');
                    texts.classList.add('animate-active');
                    up.classList.add('animate-active');
                    down.classList.add('animate-active');
                }
            }, options);
            domIo.observe(second);
            // 标题动画
            const titles = document.querySelector('.second-title');
            const titleIo = new IntersectionObserver(entries => {
                const visible = entries[0].isIntersecting;
                visible && titles.classList.add('second-title-active');
            });
            titleIo.observe(titles);
        },
        upPrev() {
            if (this.activeIndex === 0) {
                this.activeIndex = 2;
            }
            else {
                this.activeIndex--;
            }
        },
        downNext() {
            if (this.activeIndex === 2) {
                this.activeIndex = 0;
            }
            else {
                this.activeIndex++;
            }
        }
    }
};
</script>

<style lang="less" scoped>
@keyframes imgup {
    from {
        opacity: 0;
        top: 520px;
    }

    to {
        opacity: 1;
        top: 0;
    }
}
@keyframes textup {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
@keyframes titleup {
    from {
        opacity: 0;
        bottom: -114px;
    }

    to {
        opacity: 1;
        bottom: 0;
    }
}

.content {
    width: 1204px;
    margin: 51px auto 0;
    display: flex;
    justify-content: space-between;
}

.second {
    min-width: 1204px;
    margin: 185px auto 0;
    padding-bottom: 68px;

    &-title {
        text-align: center;
        height: 40px;
        line-height: 45px;
        font-size: 32px;
        color: #1e212a;
        opacity: 0;
        position: relative;
    }

    &-title-active {
        animation: .6s titleup linear;
        animation-fill-mode: forwards;
    }
}

.texts {
    display: inline-block;
    width: 466px;
    margin: auto 0;
    opacity: 0;

    .item {
        width: 466px;
        height: 72px;
        padding-top: 20px;
        text-align: left;
        border-radius: 20px;
        transition: background-color .5s;
        cursor: pointer;

        .line {
            display: inline-block;
            width: 20px;
            height: 1px;
            margin-left: 24px;
            margin-top: -10px;
            background: #fff;
            border-radius: 1px;
            vertical-align: middle;
        }

        .title {
            display: inline-block;
            padding-left: 32px;
            font-size: 22px;
            color: #1e212a;
            line-height: 25px;
            transition: color .5s;
        }

        .intro {
            width: 302px;
            padding-left: 132px;
            font-size: 14px;
            line-height: 22px;
            color: #787a7f;
            text-overflow: ellipsis;
            overflow: hidden;
            margin-top: 8px;
        }

        &.active {
            background-color: #f0f2f7;

            .line {
                background: #f5232d;
            }

            .title {
                font-weight: 600;
            }
        }

        &:hover {
            background-color: #f0f2f7;

            .title {
                font-weight: 600;
            }
        }

        span:nth-child(1) {
            display: inline-block;
            width: 20px;
            padding-right: 30px;
        }
    }

    .up-prev {
        width: 48px;
        height: 48px;
        margin: 0 auto 50px;
        background-image: url(//i.gsxcdn.com/729753231_o31ov7r5.png);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 24px 24px;
        border-radius: 4px;
        outline: none;
        opacity: 0;

        &:hover {
            background-color: #eef0f5;
        }

        &.my-button-disabled {
            background-image: url(//i.gsxcdn.com/816607122_h32ygfuq.png);
        }

        &.animate-active {
            animation: 1s textup ease-out;
            animation-delay: 1s;
            animation-fill-mode: forwards;
        }
    }

    .down-next {
        margin: 50px auto 0;
        width: 48px;
        height: 48px;
        background-image: url(//i.gsxcdn.com/729753233_2vhpqmbq.png);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 24px 24px;
        border-radius: 4px;
        outline: none;
        opacity: 0;

        &:hover {
            background-color: #eef0f5;
        }

        &.my-button-disabled {
            background-image: url(//i.gsxcdn.com/816661972_l9vx4wq1.png);
        }

        &.animate-active {
            animation: 1s textup ease-out;
            animation-delay: 1s;
            animation-fill-mode: forwards;
        }
    }

    .texts-wrapper {
        .swiper-slide {
            width: auto;
            height: 96px;
        }
    }

    &.animate-active {
        animation: .8s textup ease-out;
        animation-delay: .2s;
        animation-fill-mode: forwards;
    }
}

.cover-img {
    position: relative;
    display: inline-block;
    width: 462px;
    height: 498px;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 22px;
    background-image: url(https://i.gsxcdn.com/1728687326_kc9m07mn.png);
    opacity: 0;

    &.animate-active {
        animation: .8s imgup ease-out;
        animation-fill-mode: forwards;
    }
}
</style>
