<template>
    <div class="content">
        <div
            v-for="(card, index) in cards"
            :key="index"
            class="card"
        >
            <div class="card-img" :style="{backgroundImage: `url(${card.url})`}"></div>
            <div class="card-details">
                <div
                    class="card-title"
                    :class="{'little-margin': index >= 3}"
                >
                    {{card.title}}
                </div>
                <div class="card-content">
                    {{card.content}}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ServeCommitment',
    props: {
        cards: {
            type: Array,
            default: () => [
                {
                    title: '随材包邮',
                    content: '精美课程材料，包邮到家',
                    url: 'https://i.gsxcdn.com/1728932354_ojzm3ccn.png'
                },
                {
                    title: '自主调班',
                    content: '详情可咨询在线客服',
                    url: 'https://i.gsxcdn.com/1728932360_m2qx84lw.png'
                },
                {
                    title: '无限回放',
                    content: '课程三年内无限回放观看',
                    url: 'https://i.gsxcdn.com/1728932356_pah494ot.png'
                },
                {
                    title: '及时答疑',
                    content: '学员可以私信二讲老师或在班级群提问，解决学习困惑',
                    url: 'https://i.gsxcdn.com/1728932353_579fkicc.png'
                },
                {
                    title: '支持退课',
                    content: '课程不满意，扣除材料费并根据行课进度按比例退款',
                    url: 'https://i.gsxcdn.com/1728932358_3pjc05b3.png'
                },
                {
                    title: '资金安全',
                    content: '严格遵守国家政策合规经营，资金严格接受国家监管',
                    url: 'https://i.gsxcdn.com/1728932359_9od8ll94.png'
                },
            ]
        },
    },
    methods: {
        // 处理服务承诺
        serverCommitmentAnimation(dom) {
            // 标题动画
            const titles = dom.querySelector('.titles');
            const titleIo = new IntersectionObserver(entries => {
                const visible = entries[0].isIntersecting;
                visible && titles.classList.add('title-active');
            });
            titleIo.observe(titles);
            // // 内容区动画
            const content = dom.querySelector('.content');
            const contentIo = new IntersectionObserver(entries => {
                const visible = entries[0].isIntersecting;
                visible && content.classList.add('active');
            });
            contentIo.observe(content);
        },
    }
};
</script>

<style lang="less" scoped>
@keyframes rowup {
    from {
        opacity: 0;
        top: 370px;
    }

    to {
        opacity: 1;
        top: 0;
    }
}

.content {
    width: 1204px;
    margin: 92px auto 0;
    display: flex;
    flex-wrap: wrap;
    opacity: 0;
    position: relative;

    &.active {
        animation: .8s rowup ease-out;
        animation-fill-mode: forwards;
    }

    .card {
        width: 362px;
        margin-bottom: 75px;

        &:not(:nth-of-type(3n)) {
            margin-right: 59px;
        }

        &-img {
            float: left;
            width: 133px;
            height: 144px;
            background-size: cover;
            background-repeat: no-repeat;
        }

        &-details {
            margin-left: 147px;
        }

        &-title {
            margin-top: 13px;
            font-size: 24px;
            line-height: 40px;
            font-weight: 500;
            color: #1e212a;
        }

        .little-margin {
            margin-top: 6px;
        }

        &-content {
            font-size: 16px;
            line-height: 28px;
            color: #6d718e;
        }
    }
}
</style>
